<template>
    <div>
        <Input type="text" v-model="id" number placeholder="id"></Input>
        <p>{{info}}</p>
        <row>
            <Input type="text" v-model="keyword" number placeholder="搜索标题" search></Input>
            <Button type="primary" icon="ios-search" @click="searchStage">搜索</Button>
            <row>
                <RadioGroup v-model="id" vertical>
                    <template v-for="item in stageList">
                        <Radio :label="item.id">
                            <span>{{item.name_zh}} {{item.name_en}}</span>
                        </Radio>
                    </template>
                </RadioGroup>
            </row>
        </row>
    </div>
</template>
<script>
import { StageInfo, StageSearch } from '@/api/games/stage';

export default {
  name: 'stage-finder',
  props: ['value', 'game_id'],
  components: {},
  data () {
    return {
      id: this.id,
      stageList: [],
      keyword: '',
      info: ''
    }
  },
  watch: {
    'id' (val) {
      this.getInfo()
      this.$emit('input', val)
    },
    'value' (value) {
      this.id = value
    },
    'game_id' (value) {
      this.stageList = []
      this.keyword = ''
      this.info = ''
      this.id = 0
    }

  },
  mounted () {
    this.id = this.value
  },
  methods: {
    getInfo () {
      let self = this
      if (!self.id) {
        return
      }
      StageInfo({ id: self.id, game_id: self.game_id })
        .then((response) => {
          if (response.data.info.id > 0){
            self.info = `${response.data.info.name_zh + response.data.info.name_en}`;
          }else{
            self.info = '';
          }
        })
    },
    searchStage () {
      let self = this;
      if (self.keyword) {
        StageSearch({
          keyword: self.keyword,
          game_id: self.game_id
        }).then((response) => {
          if (response.data.code === 0) {
            self.stageList = response.data.list
          }
        })
      }
    }
  }
}
</script>
