<template>
  <div>
    <Input type="text" v-model="round_id" number placeholder="轮次id"></Input>
    <p>{{info}}</p>
    <row>
      <Input type="text" v-model="round_name" number placeholder="轮次名称"></Input>
      <Button type="primary" icon="ios-search" @click="searchTeam">搜索轮次</Button>
      <row>
        <RadioGroup v-model="round_id" vertical>
          <template v-for="item in teamList">
            <Radio :label="item.id">
              <span>{{item.name}}</span>
            </Radio>
          </template>
        </RadioGroup>
      </row>
    </row>
  </div>
</template>
<script>
// import TournamentSelector from "../components/tournament-selector.vue";
import { GameRoundInfo, GameRoundSearch } from '@/api/games/round';

export default {
  name: 'team-finder',
  props: ['value', 'game_id'],
  components: {
    // TournamentSelector
  },
  data () {
    return {
      round_id: this.value,
      round_name: null,
      teamList: [],
      info: ''
    }
  },
  watch: {
    round_id (val) {
      this.getTeamInfo();
      // this.$emit('input', val);
    },
    value (val) {
      this.round_id = val
    }
  },
  mounted () {
    this.round_id = this.value;
    this.getTeamInfo();
  },
  methods: {
    getTeamInfo () {
      let self = this
      if (self.round_id) {
        GameRoundInfo({ id: self.round_id, game_id: self.game_id })
          .then(response => {
            if (response.data.info) {
              self.info = response.data.info.name;
              self.teamList = [];
              self.$emit( "listenRoundidUpdate",  JSON.stringify( {
                round_id: self.round_id,
                round_name: self.info,
              }) );
            } else {
              self.info = '';
            }
          })
      } else {
        self.info = '';
      }
    },
    searchTeam () {
      let self = this
      if (self.game_id || self.round_name) {
        GameRoundSearch({
          game_id: self.game_id,
          name: self.round_name
        })
          .then(response => {
            // console.log(response)
            if (response.data.code == 0) {
              self.teamList = response.data.list
            }
          })
      }
    }
  }
}
</script>
