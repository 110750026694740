<template>
    <div>
        <Modal
            title="添加队伍"
            v-model="modal_add_board_team"
            @on-ok="apiAddBoardTeam"
        >
            <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
                <FormItem label="搜索队伍">
                    <teamSearch
                        :game_id="parseInt(game_id)"
                        v-model="new_team"
                    ></teamSearch>
                </FormItem>
            </Form>
        </Modal>
        <Modal
            :title="'编辑' + edited_board_team.name + '战队积分榜'"
            v-model="modal_edit_board_team"
            @on-ok="ApiUpdateBoardTeam"
        >
            <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
                <FormItem label="胜:">
                <Row>
                    <Col span="4">基础值: </Col>
                    <Col span="8">
                        <Input v-model="edited_board_team.win " type="number" number ></Input>
                    </Col>
                    <Col span="4">偏移量:</Col>
                    <Col span="8"
                    ><Input
                        v-model="edited_board_team.win_offset"
                        type="number"
                        number
                    ></Input></Col>
                </Row>
                </FormItem>
                <FormItem label="负:">
                <Row>
                    <Col span="4">基础值: </Col>
                    <Col span="8">
                        <Input v-model="edited_board_team.lose " type="number" number ></Input>
                    </Col>
                    <Col span="4">偏移量:</Col>
                    <Col span="8"
                    ><Input
                        v-model="edited_board_team.lose_offset"
                        type="number"
                        number
                    ></Input
                    ></Col>
                </Row>
                </FormItem>
                <FormItem label="平:">
                <Row>
                    <Col span="4">基础值: </Col>
                    <Col span="8">
                        <Input v-model="edited_board_team.draw" type="number" number ></Input>
                    </Col>
                    <Col span="4">偏移量:</Col>
                    <Col span="8">
                    <Input
                        v-model="edited_board_team.draw_offset"
                        type="number"
                        number
                    ></Input
                    ></Col>
                </Row>
                </FormItem>
            </Form>
        </Modal>
        <Row>
            <Col span=12>
                <Button type="info" long @click="addBoardTeamModal()">添加队伍</Button>
            </Col>
            <Col span=12>
                <Button type="success" long @click="updateOrder()">更新排序</Button>
            </Col>
        </Row>
        <fitTable
            v-for="(table, index2) in data"
            :key="index2"
            :columns="table_columns"
            :data="table.list"
            :header_height="300"
        >
        </fitTable>
    </div>
</template>
<script>
import fitTable from "../../basic/table.vue";
import {
  AddBoardTeam,
  DelBoardTeam,
  EditBoardTeam,
  updateBoardTeamOrder,
} from "@/api/games/tournament";
import teamSearch from "../team/search.vue";
import Lock from '../../ballsports/lock.vue';

const delButton = (vm, h, params) => {
    return h(
        "Poptip",
        {
            props: {
                confirm: true,
                title: "您确定要删除这条数据吗?",
                transfer: true,
            },
            on: {
                "on-ok": () => {
                    var data = {
                        game_id: vm.game_id,
                        tournament_id: vm.tournament_id,
                        stage_id: vm.stage_id,
                        group_id: vm.stage_id,
                        row_id: params.row.id,
                    };
                    DelBoardTeam(data).then((response) => {
                        if (response.data.code == 0) {
                            // vm..splice(params.index, 1);
                            // vm.getStates(vm.stage_id, vm.group_id);
                            vm.$emit("updateBoardScore", vm.stage_id, vm.group_id);
                        } else {
                        this.$Message.error(response.data.msg);
                        }
                    });
                },
            },
        },
        [h(
            "Button",
            {
                style: {
                    // margin: "0 5px"
                },
                props: {
                    type: "warning",
                    size: "small",
                },
            },
            "删除"
        )]
    );
};

const editButton = (vm, h, params) => {
  return h(
    "Button",
    {
      props: {
        type: "success",
        size: "small",
      },
      on: {
        click: () => {
          // 展示模态框
          vm.edited_board_team = {
            row_id: params.row.id,
            win: params.row.win,
            lose: params.row.lose,
            draw: params.row.draw,
            win_offset: params.row.win_offset || 0,
            lose_offset: params.row.lose_offset || 0,
            draw_offset: params.row.draw_offset || 0,
            name: params.row.name, // 战队名称
          };
          vm.modal_edit_board_team = true;
        },
      },
    },
    "编辑"
  );
};

export default {
    name: "board-score",
    props: ['data', "game_id", "tournament_id", "stage_id", "group_id"],
    data() {
        let self = (window.x = this);
        return {
            new_team: {id:0, name:''},
            modal_add_board_team: false,
            modal_edit_board_team: false,

            table_columns: [
                {
                    title: "id",
                    key: "id",
                    width: 100,
                },
                {
                    title: "战队",
                    key: "name",
                    minWidth: 80,
                },
                {
                    title: "胜",
                    key: "win",
                    minWidth: 80,
                },
                {
                    title: "负",
                    key: "lose",
                    minWidth: 80,
                },
                {
                    title: "平",
                    key: "draw",
                    minWidth: 80,
                },
                {
                    title: "积分",
                    key: "score",
                    minWidth: 80,
                },
                {
                    title: '小分/回合差',
                    key: 'rounds_sub',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        var round_sub = currentRow.round_sub;
                        var ms_score = currentRow.ms_score;
                        var value = ms_score || round_sub || '';
                        return h(
                          'div', [
                            h('span', {
                              attrs: {
                                // class: 'player_name'
                              },
                            }, value),
                          ]
                        )
                    }
                },

                {
                    title: "排序",
                    key: "order",
                    minWidth: 80,
                    render: function(h, params){
                        let currentRow = params.row;
                        return [
                            h(
                                'Input', 
                                {
                                    'props': {
                                        'value': currentRow.order,
                                    },
                                    on: {
                                        'on-blur': (event) => {
                                            const new_order = event.target.value;
                                            // console.log(new_order, currentRow.order);
                                            if (parseInt(new_order) === parseInt(currentRow.order)){
                                                return
                                            }
                                            var data = {
                                                type: 1,
                                                game_id: self.game_id,
                                                tournament_id: self.tournament_id,
                                                stage_id: self.stage_id,
                                                group_id: self.group_id,
                                                row_id: currentRow.id,
                                                params: {
                                                    order: new_order,
                                                },
                                            };
                                            // console.log(data);
                                            EditBoardTeam(data).then((response) => {
                                                if (response.data.code === 0) {
                                                    self.$Message.success("保存成功");
                                                    // 重新获取榜单
                                                    // self.$emit("updateBoardScore", self.stage_id, self.group_id);
                                                } else {
                                                    self.$Message.error(response.data.msg);
                                                }
                                            });
                                        }
                                    },
                                },
                            ),
                            h('Select',
                                {
                                    props:{
                                        'value': currentRow.order_lock,
                                    },
                                    on: {
                                        'on-change':(event) => {
                                            const new_lock = event;
                                            // console.log(new_lock)
                                            var data = {
                                                type: 2,
                                                game_id: self.game_id,
                                                tournament_id: self.tournament_id,
                                                stage_id: self.stage_id,
                                                group_id: self.group_id,
                                                team_id: currentRow.team_id,
                                                order_lock: new_lock,
                                            };
                                            // console.log(data);
                                            EditBoardTeam(data).then((response) => {
                                                if (response.data.code === 0) {
                                                    self.$Message.success("保存成功");
                                                } else {
                                                    self.$Message.error(response.data.msg);
                                                }
                                            });
                                        }
                                    }
                                },
                                [
                                    {"value":0, name:"自动"},
                                    {"value":1, name:"手动"}
                                ].map((item) =>{
                                    return h('Option', {
                                        props: {
                                            value: item.value,
                                            label: item.name
                                        }
                                    })
                                }),
                            ),
                        ]
                    }
                },
                
                {
                    title: "操作",
                    key: "deleted",
                    align: "center",
                    width: 100,
                    render(h, params) {
                        return [delButton(self, h, params), editButton(self, h, params)];
                    },
                },
            ],
            edited_board_team: {
                row_id: 0,
                win: 0,
                lose: 0,
                draw: 0,
                win_offset: 0,
                lose_offset: 0,
                draw_offset: 0,
            },
        }
    },
    components: {
        fitTable,
        teamSearch,
        Lock,
    },
    methods: {
        addBoardTeamModal() {
            let self = this;
            self.new_team = {id:0, name:''};
            self.modal_add_board_team = true;
        },
        // end
        apiAddBoardTeam() {
            let self = this;
            var params = {
                game_id: self.game_id,
                tournament_id: self.tournament_id,
                stage_id: self.stage_id,
                group_id: self.group_id,
                team_id: self.new_team.id,
            };
            AddBoardTeam(params).then((response) => {
                if (response.data.code === 0) {
                    self.$Message.success("保存成功");
                    // 重新获取榜单
                    // self.getStates(self.stage_id, self.group_id);
                    self.$emit("updateBoardScore", self.stage_id, self.group_id);
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },
        //end
        ApiUpdateBoardTeam() {
            let self = this;
            // 1 更新偏移量
            var data = {
                game_id: self.game_id,
                tournament_id: self.tournament_id,
                row_id: self.edited_board_team.row_id,
                params: {
                    win_offset: self.edited_board_team.win_offset,
                    lose_offset: self.edited_board_team.lose_offset,
                    draw_offset: self.edited_board_team.draw_offset,
                },
                stage_id: self.stage_id,
                group_id: self.group_id,
            };
            // console.log(data);
            EditBoardTeam(data).then((response) => {
                if (response.data.code === 0) {
                    self.$Message.success("保存成功");
                    // 重新获取榜单
                    // self.getStates(self.stage_id, self.group_id);
                    // self.$emit("updateBoardScore", self.stage_id, self.group_id);
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
            // 2 更新基础值
            var data = {
                type: 1,
                game_id: self.game_id,
                tournament_id: self.tournament_id,
                stage_id: self.stage_id,
                group_id: self.group_id,
                row_id: self.edited_board_team.row_id,
                params: {
                    win: self.edited_board_team.win,
                    lose: self.edited_board_team.lose,
                    draw: self.edited_board_team.draw,
                },
            };
            // console.log(data);
            EditBoardTeam(data).then((response) => {
                if (response.data.code === 0) {
                    self.$Message.success("保存成功");
                    // 重新获取榜单
                    self.$emit("updateBoardScore", self.stage_id, self.group_id);
                } else {
                    self.$Message.error(response.data.msg);
                }
            });
        },
        // end
        updateOrder(){
            let self = this;
            let params = {
                game_id: self.game_id,
                tournament_id: self.tournament_id,
                stage_id: self.stage_id,
                group_id: self.group_id,
            }
            updateBoardTeamOrder(params).then(response => {
                if (response.data.code === 0) {
                    self.$Message.success("更新成功");
                    // 重新获取榜单
                    self.$emit("updateBoardScore", self.stage_id, self.group_id);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
    },
    mounted() {

    },
    watch: {

    },
    computed: {}
}
</script>