
<template>
  <div style="width: 100%">
    <Modal title="新增大阶段" v-model="modal_stage" @on-ok="apiAddNewStage">
      <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
        <FormItem label="查找阶段">
          <StageFinder :game_id="game_id" v-model="new_stage.id"></StageFinder>
        </FormItem>
      </Form>
    </Modal>

    <Modal title="新增子阶段" v-model="modal_group" @on-ok="apiAddNewGroup">
      <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
        <FormItem label="子阶段状态">
          <Checkbox v-model="new_group.has_sub">新增子阶段</Checkbox>
        </FormItem>
        <FormItem label="查找阶段" v-if="new_group.has_sub">
          <StageFinder
            :game_id="game_id"
            v-model="new_group.group_id"
          ></StageFinder>
        </FormItem>
        <FormItem label="类型">
          <RadioGroup v-model="new_group.type">
            <Radio label="1">积分榜</Radio>
            <Radio label="2">对阵图</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
    </Modal>

    

    

    <Modal
      title="新增赛程"
      draggable
      v-model="modal_add_stage_match"
      @on-ok="apiAddStageMatch"
    >
      <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
        <FormItem label="搜索比赛">
          <MatchFinder
            v-model="new_stage_match.match_id"
            :game_id="game_id"
            :tournament_id="tournament_id"
            :match="{}"
          >
          </MatchFinder>
          <Button type="info" @click="add_to_stage_match_ids()"
            >添加id至新增</Button
          >
        </FormItem>
        <FormItem label="新增比赛id">
          <Tag
            v-for="item in new_stage_match.match_ids"
            :key="item"
            :name="item"
            closable
            type="border"
            @on-close="del_from_stage_match_ids"
            >{{ item }}</Tag
          >
        </FormItem>
      </Form>
    </Modal>

    <Modal
      title="编辑赛程"
      v-model="modal_edit_stage_match"
      @on-ok="apiEditStageMatch"
    >
      <Form :label-width="100" style="margin-top: 1px" @submit.native.prevent>
        <FormItem label="ID">
          <Input v-model="new_stage_match.id" disabled></Input>
        </FormItem>
        <FormItem label="搜索比赛">
          <MatchFinder
            v-model="new_stage_match.match_id"
            :game_id="game_id"
            :tournament_id="tournament_id"
            :match="{}"
          >
          </MatchFinder>
        </FormItem>
      </Form>
    </Modal>

    <Row :gutter="10" style="margin: 3px 0 3px 0; text-align: center">
      <Col span="8" style="border-right: 1px solid black">
        <template>
          <div style="background: #eee; padding: 5px">
            <Card :bordered="false">
              <Row style="margin: 0px 0px 20px 0px">
                <Col span="4">
                  <Avatar
                    shape="square"
                    icon="ios-person"
                    size="large"
                    :src="tournament.logo"
                  />
                </Col>

                <Col
                  span="20"
                  class_name="tournament_descs"
                  push="1"
                  align="left"
                >
                  <Row
                    ><span>{{
                      tournament.name_zh || tournament.abbr_zh
                    }}</span></Row
                  >
                  <Row
                    ><span
                      >状态:
                      {{
                        tournament.status_id === 1
                          ? "未开始"
                          : tournament.status_id === 2
                          ? "进行中"
                          : tournament.status_id === 3
                          ? "已结束"
                          : "未知"
                      }}</span
                    ></Row
                  >
                  <Row
                    >时间: {{ tournament_start_date }} -
                    {{ tournament_end_date }}</Row
                  >
                </Col>
              </Row>
            </Card>
          </div>
          <div>
            <Row>
              <Col span="8" align="left">
                <DatePicker
                  type="date"
                  v-model="date"
                  placeholder="日期筛选"
                  @on-change="onDateChange"
                ></DatePicker>
              </Col>

              <Col span="8" align="left">
                <Select
                  v-model="stage_id"
                  @on-change="onStageChange"
                  clearable
                  placeholder="阶段筛选"
                >
                  <Option
                    v-for="item in tournament.stages"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name_zh || item.name_en }}</Option
                  >
                </Select>
              </Col>

              <Col span="8" align="left">
                <Select
                  v-model="status_id"
                  @on-change="onStatusChange"
                  clearable
                  placeholder="状态筛选"
                >
                  <Option
                    v-for="item in status"
                    :value="item.id"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </Col>
            </Row>
            <fitTable
              :columns="match_columns"
              :data="matches"
              @on-row-click="matchRowClicked"
              :header_height="320"
            ></fitTable>
            <Page
              :total="total_page"
              :current="current_page"
              :page-size="page_size"
              @on-change="onPageChange"
              show-total
              show-elevator
            ></Page>
          </div>
        </template>
      </Col>
      <Col span="16" style="border-right: 0px solid black">
        <template>
          <span class="text-main"
            >{{ board_stage_id }}-{{ board_group_id }}</span
          >
          <Tabs
            type="card"
            name="tab1"
            v-model="board_stage_id"
            capture-focus
            closable
            @on-tab-remove="handleTabStageRemove"
            :before-remove="beforeTabStageRemove"
          >
            <TabPane
              v-for="item in stages"
              :key="item.id"
              :label="item.name"
              :name="String(item.id)"
              tab="tab1"
            >
              <Tabs
                type="card"
                name="tab2"
                v-if="stage_groups[item.id]"
                v-model="board_group_id"
                closable
                @on-tab-remove="handleTabGroupRemove"
                :before-remove="beforeTabGroupRemove"
              >
                <TabPane
                  v-for="group in stage_groups[item.id]"
                  :name="String(group.group_id)"
                  :key="group.group_id"
                  :label="group.group_name || '-'"
                  tab="tab2"
                >
                  <!-- 积分榜 或者 对阵图 -->
                  <!-- <Row>积分榜 或者 对阵图选择 - 与 配置信息 </Row> -->
                  <Row type="flex" justify="start">
                    <Col span="2">
                      <span class="text-main" v-if="group.type === 1"
                        >积分榜:</span
                      >
                      <span class="text-main" v-if="group.type === 2"
                        >对阵图:</span
                      >
                    </Col>
                    <Col span="4" v-if="group.type === 2">
                      <RadioGroup
                        v-model="
                          groups_conf[group.stage_id + '-' + group.group_id]
                            .sub_type
                        "
                      >
                        <Radio :label="1">单淘赛</Radio>
                        <Radio :label="2">双淘赛</Radio>
                      </RadioGroup>
                    </Col>
                    <Col span="4" v-if="group.type === 2">
                      <Select
                        v-model="
                          groups_conf[group.stage_id + '-' + group.group_id]
                            .teams
                        "
                        transfer
                        placeholder="队伍数"
                      >
                        <Option value="4">4</Option>
                        <Option value="8">8</Option>
                        <Option value="16">16</Option>
                      </Select>
                    </Col>
                    <Col
                      span="5"
                      v-if="
                        groups_conf[group.stage_id + '-' + group.group_id]
                          .sub_type === 1
                      "
                    >
                      <Checkbox
                        v-model="
                          groups_conf[group.stage_id + '-' + group.group_id]
                            .third
                        "
                        >第三方争夺</Checkbox
                      >
                    </Col>
                    <Col span="4" v-if="group.type === 1">
                      <Input
                        placeholder="胜场系数:"
                        v-model="
                          groups_conf[group.stage_id + '-' + group.group_id]
                            .win_score
                        "
                        type="number"
                        number
                      >
                      </Input>
                    </Col>
                    <Col span="4" v-if="group.type === 1">
                      <Input
                        placeholder="平场系数:"
                        v-model="
                          groups_conf[group.stage_id + '-' + group.group_id]
                            .draw_score
                        "
                        type="number"
                        number
                      >
                      </Input>
                    </Col>
                    <Col span="4" v-if="group.type === 1">
                      <Input
                        placeholder="负场系数:"
                        v-model="
                          groups_conf[group.stage_id + '-' + group.group_id]
                            .lose_score
                        "
                        type="number"
                        number
                      >
                      </Input>
                    </Col>
                    <Col span="2" style="text-align: right">
                      <Button
                        type="info"
                        style="text-align: right"
                        @click="
                          saveGroupConf(
                            group,
                            groups_conf[group.stage_id + '-' + group.group_id]
                          )
                        "
                        >保存配置</Button
                      >
                    </Col>
                  </Row>
                  <MatchMap
                    v-if="group.type === 2"
                    :stage_id="group.stage_id"
                    :group_id="group.group_id"
                    :value="
                      table_matches[group.stage_id + '-' + group.group_id]
                    "
                    :sub_type="
                      groups_conf[group.stage_id + '-' + group.group_id]
                        .sub_type
                    "
                    :teams="
                      groups_conf[group.stage_id + '-' + group.group_id].teams
                    "
                    :third="
                      groups_conf[group.stage_id + '-' + group.group_id].third
                    "
                    :game_id="game_id"
                    :tournament_id="tournament_id"
                    v-on:listenRefresh="listenRefresh"
                  ></MatchMap>
                  <Tabs
                    v-else-if="group.type === 1"
                    name="tab3"
                    type="card"
                    @on-click="onStageMatchClick"
                  >
                    <TabPane label="积分榜" tab="tab3">
                      <boardScore 
                        :data="table_matches[ group.stage_id + '-' + group.group_id]"
                        :game_id = 'game_id'
                        :tournament_id="tournament_id"
                        :stage_id="board_stage_id"
                        :group_id="board_group_id"
                        @updateBoardScore="updateBoardScore"
                      ></boardScore>
                    </TabPane>
                    <TabPane label="赛程管理" tab="tab3">
                      <Button type="info" long @click="addStageMatchModal()"
                        >添加赛程</Button>
                      <!-- 表格 -->
                      <fitTable
                        :columns="stage_match_cols"
                        :data="
                          stage_matches[group.stage_id + '-' + group.group_id]
                        "
                        :header_height="300"
                      >
                      </fitTable>
                    </TabPane>
                  </Tabs>
                </TabPane>
                <Button @click="handleGroupAdd" slot="extra">增加子阶段</Button>
              </Tabs>
            </TabPane>
            <Button @click="handleStageAdd" slot="extra">增加主阶段</Button>
          </Tabs>
        </template>
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  TournamentDetail,
  TournamentMatches,
  TournamentStages,
  CacheStageAdd,
  CacheGroupAdd,
  SaveGroupConf,
  GetStates,
  DelStage,
  GetStageMatches,
  AddStageMatches,
  DelStageMatches,
  UpdateStageMatches,
} from "@/api/games/tournament";
import { UpdateMatch } from "@/api/games/match";
import moment from "moment";
import StageFinder from "../stage/finder.vue";
import MatchMap from "../match/map.vue";
import MatchFinder from "../match/finder.vue";
import fitTable from "../../basic/table.vue";
import boardScore from './board_score.vue';



const delStageMatchButton = (vm, h, params) => {
  return h(
    "Poptip",
    {
      props: {
        confirm: true,
        title: "您确定要删除这条数据吗?",
        transfer: true,
      },
      on: {
        "on-ok": () => {
          var data = {
            id: params.row.id,
          };
          DelStageMatches(data).then((response) => {
            if (response.data.code == 0) {
              var _key =
                String(vm.board_stage_id) + "-" + String(vm.board_group_id);
              vm.stage_matches[_key].splice(params.index, 1);
              // vm.getStageMatch();
            } else {
              this.$Message.error(response.data.msg);
            }
          });
        },
      },
    },
    [
      h(
        "Button",
        {
          style: {
            // margin: "0 5px"
          },
          props: {
            type: "warning",
            size: "small",
          },
        },
        "删除"
      ),
    ]
  );
};

const editStageMatchButton = (vm, h, params) => {
  return h(
    "Button",
    {
      props: {
        type: "success",
        size: "small",
      },
      on: {
        click: () => {
          // 展示模态框
          vm.new_stage_match = {
            id: params.row.id,
            match_id: params.row.match_id,
            match_ids: [],
          };
          vm.modal_edit_stage_match = true;
        },
      },
    },
    "编辑"
  );
};
export default {
  data() {
    let self = (window.x = this);
    return {
      new_group: {
        has_sub: false,
        stage_id: 0,
        group_id: 0,
        type: 1,
        sub_type: 0,
      },
      new_stage: { id: 0 },
      modal_stage: false,
      modal_group: false,
      modal_sub_stage: false,
      games: [
        { id: 1, name: "lol" },
        { id: 2, name: "csgo" },
        { id: 3, name: "dota" },
        { id: 6, name: "kog" },
      ],
      game_id: 0,
      tournament_id: 0,
      loading: true,
      tournament: {
        logo: "",
      },
      matches: [],
      stages_dic: {},
      match_columns: [
        {
          title: "时间",
          key: "match_time",
          width: "100",
          render: function (h, params) {
            let currentRow = params.row;
            var time_str = moment
              .unix(currentRow.match_time)
              .format("YYYY-MM-DD HH:mm");
            return h("div", [
              h("Row", time_str.split(" ")[0]),
              h("Row", time_str.split(" ")[1]),
            ]);
          },
        },
        {
          title: "阶段",
          key: "stage_id",
          align: "center",
          render: function (h, params) {
            let currentRow = params.row;
            var home_name =
              currentRow.home.abbr_en ||
              currentRow.home.abbr_zh ||
              currentRow.home.name_en ||
              currentRow.home.name_zh ||
              "";
            var away_name =
              currentRow.away.abbr_en ||
              currentRow.away.abbr_zh ||
              currentRow.away.name_en ||
              currentRow.away.name_zh ||
              "";
            var stage = self.stages_dic[currentRow.stage_id] || {};
            var stage_name = stage.name_zh || stage.name_en || "";
            return h("div", [
              h(
                "Row",
                {
                  style: { "margin-top": "10px", "margin-bottom": "10px" },
                },
                [
                  h(
                    "i-col",
                    {
                      attrs: {
                        span: 9,
                      },
                    },
                    home_name
                  ),
                  h(
                    "i-col",
                    {
                      attrs: {
                        span: 2,
                      },
                    },
                    currentRow.home_score
                  ),
                  h(
                    "i-col",
                    {
                      attrs: {
                        span: 2,
                      },
                    },
                    ":"
                  ),
                  h(
                    "i-col",
                    {
                      attrs: {
                        span: 2,
                      },
                    },
                    currentRow.away_score
                  ),
                  h(
                    "i-col",
                    {
                      attrs: {
                        span: 9,
                      },
                    },
                    away_name
                  ),
                ]
              ),
              // stage_name  + " & ID: "+(currentRow.stage_id)
              h("Row", [
                h(
                  "i-col",
                  {
                    attrs: {
                      span: 8,
                    },
                  },
                  [
                    h("span", stage_name + " 阶段ID: "),
                    // h('i-input', {}), // 换行了
                  ]
                ),

                h(
                  "i-col",
                  {
                    attrs: {
                      span: 16,
                    },
                  },
                  [
                    h("i-input", {
                      props: {
                        type: "number",
                        value: currentRow.stage_id,
                      },
                      on: {
                        "on-blur": (event) => {
                          const new_stage_id = event.target.value;
                          // console.log(currentRow);
                          // console.log(currentRow.stage_id);
                          // currentRow.time_left_str = event.target.value;
                          var params = {
                            game_id: currentRow.game_id,
                            id: currentRow.id,
                            kvs: { stage_id: new_stage_id },
                          };
                          // console.log(params)
                          UpdateMatch(params).then((response) => {
                            if (response.data.code == 0) {
                              // 重新获取文字直播
                              self.$Message.success("更新成功");
                            } else {
                              self.$Message.error(response.data.msg);
                            }
                          });
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]);
          },
        },
        {
          title: "状态",
          key: "status_id",
          align: "center",
          width: "100",
          render: function (h, params) {
            let currentRow = params.row;
            switch (currentRow.status_id) {
              case 1:
                var status_name = "未开始";
                break;
              case 2:
                var status_name = "进行中";
                break;
              case 3:
                var status_name = "已结束";
                break;
              default:
                var status_name = "未知";
            }
            return h("div", [
              h("Row", [
                h(
                  "i-col",
                  {
                    attrs: {
                      span: 24,
                    },
                  },
                  status_name
                ),
              ]),
              h("Row", [
                h(
                  "i-col",
                  {
                    attrs: {
                      span: 24,
                    },
                  },
                  currentRow.id
                ),
              ]),
            ]);
          },
        },
      ],
      stage_id: 0,
      date: "",
      status_id: 0,
      status: [
        { id: 1, name: "未开始" },
        { id: 2, name: "进行中" },
        { id: 3, name: "已结束" },
      ],
      total_page: 0,
      page_size: 0,
      current_page: 1,
      stages: [],
      stage_groups: {},
      board_stage_id: null, // 对阵图stage_id
      board_group_id: null, // 对阵图group_id
      groups_conf: {}, // 子阶段的配置信息
      table_matches: {},
      
      // 赛程表数据
      stage_matches: {},
      modal_add_stage_match: false,
      new_stage_match: {
        row_id: 0, // 赛程表的id
        match_id: 0,
        match_ids: [],
      },
      stage_match_cols: [
        // 赛程表格
        {
          title: "id",
          key: "id",
        },
        {
          title: "比赛id",
          key: "match_id",
        },
        {
          title: "赛制",
          key: "box",
          render: function (h, params) {
            let currentRow = params.row;
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    // class: 'player_name'
                  },
                },
                "BO" + String(currentRow.box)
              ),
            ]);
          },
        },
        {
          title: "主队",
          key: "home",
          render: function (h, params) {
            let currentRow = params.row;
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    // class: 'player_name'
                  },
                },
                currentRow.home.abbr_en ||
                  currentRow.home.abbr_zh ||
                  currentRow.home.name_en ||
                  currentRow.home.name_zh ||
                  ""
              ),
            ]);
          },
        },
        {
          title: "客队",
          key: "away",
          render: function (h, params) {
            let currentRow = params.row;
            return h("div", [
              h(
                "span",
                {
                  attrs: {
                    // class: 'player_name'
                  },
                },
                currentRow.away.abbr_en ||
                  currentRow.away.abbr_zh ||
                  currentRow.away.name_en ||
                  currentRow.away.name_zh ||
                  ""
              ),
            ]);
          },
        },
        {
          title: "状态",
          key: "status_id",
          render: function (h, params) {
            let currentRow = params.row;
            return h("div", [
              h(
                "span",
                {},
                currentRow.status_id === 1
                  ? "未开始"
                  : currentRow.status_id === 2
                  ? "进行中"
                  : currentRow.status_id === 3
                  ? "已结束"
                  : "状态id:" + String(currentRow.status_id)
              ),
            ]);
          },
        },
        {
          title: "操作",
          key: "deleted",
          align: "center",
          render(h, params) {
            return [
              editStageMatchButton(self, h, params),
              delStageMatchButton(self, h, params),
            ];
          },
        },
      ],
      // end
      modal_edit_stage_match: false,
    };
  },
    components: {
        StageFinder,
        MatchMap,
        MatchFinder,
        fitTable,
        boardScore,
    },
  methods: {
    // startPos
    getDataDetail() {
      let self = this;
      var params = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
      };
      TournamentDetail(params).then((response) => {
        if (response.data.code == 0) {
          self.tournament = response.data.data;
          for (var _index in self.tournament.stages) {
            var item = self.tournament.stages[_index];
            self.stages_dic[item.id] = item;
          }
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // 获取联赛下的比赛列表
    getDataMatches() {
      let self = this;
      var params = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: self.stage_id,
        date: self.date,
        page: self.current_page,
        status_id: self.status_id,
      };
      TournamentMatches(params).then((response) => {
        if (response.data.code === 0) {
          self.matches = response.data.data.list;
          self.total_page = response.data.data.total_page;
          self.page_size = response.data.data.page_size;
          self.current_page = response.data.data.current_page;
          self.loading = false;
          // console.log(self.matches);
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    getTournamentStages() {
      let self = this;
      var params = {
        game_id: self.game_id,
        id: self.tournament_id,
      };
      TournamentStages(params).then((response) => {
        if (response.data.code === 0) {
          self.stages = response.data.data.stages;
          self.stage_groups = response.data.data.stage_groups;
          self.groups_conf = response.data.data.groups_conf;
          // console.log(self.matches);
          if (!self.board_stage_id) {
            if (self.stages && self.stages.length > 0) {
              var stage_id = self.stages[0].id;
              self.board_stage_id = String(stage_id);
              if (
                self.stage_groups[stage_id] &&
                self.stage_groups[stage_id].length > 0
              ) {
                self.board_group_id = String(
                  self.stage_groups[stage_id][0].group_id
                );
              }
            }
          }
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    onPageChange(page) {
      let self = this;
      this.current_page = page;
      self.getDataMatches();
    },
    // end
    onDateChange(value) {
      let self = this;
      this.date = value;
      self.getDataMatches();
    },
    // end
    onStageChange(value) {
      let self = this;
      this.stage_id = value;
      self.getDataMatches();
    },
    // end
    onStatusChange(value) {
      let self = this;
      this.status_id = value;
      self.getDataMatches();
    },
    //end
    // 批量替换
    hookReplaceAll() {
      String.prototype.replaceAll = function (s1, s2) {
        return this.replace(new RegExp(s1, "gm"), s2);
      };
    },
    //end
    handleStageAdd() {
      let self = this;
      // console.log(1);
      self.modal_stage = true;
      self.new_stage.id = 0;
    },
    //end
    handleGroupAdd() {
      let self = this;
      // console.log(2);
      self.new_group.stage_id = self.board_stage_id;
      self.new_group.group_id = 0;
      self.new_group.type = 1;
      self.new_group.sub_type = 0;
      // console.log(self.new_group);
      self.modal_group = true;
    },
    // end
    apiAddNewStage() {
      let self = this;
      // console.log(self.new_stage);
      // 上传到服务器
      var data = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: self.new_stage.id,
      };
      CacheStageAdd(data).then((response) => {
        if (response.data.code === 0) {
          // console.log(self.matches);
          self.getTournamentStages();
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    apiAddNewGroup() {
      let self = this;
      // console.log(self.new_group);
      // return
      // 上传到服务器
      var data = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: self.new_group.stage_id,
        group_id: self.new_group.group_id,
        type: parseInt(self.new_group.type),
      };
      CacheGroupAdd(data).then((response) => {
        if (response.data.code === 0) {
          // console.log(self.matches);
          self.getTournamentStages();
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    saveGroupConf(group, conf) {
      let self = this;
      // console.log(group);
      // console.log(conf);
      conf.type = group.type;
      var data = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: group.stage_id,
        group_id: group.group_id,
        conf: conf,
      };
      SaveGroupConf(data).then((response) => {
        if (response.data.code === 0) {
          self.$Message.success("保存成功");
          // todo 生成积分榜或者对阵图
          // self.demoGraph( group.stage_id, group.group_id);
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    //end
    createNodeId(group) {
      return (
        "mountNode_" + String(group.stage_id) + "_" + String(group.group_id)
      );
    },
    // end
    getStates(stage_id, group_id, type) {
      let self = this;
      if (typeof stage_id == undefined) {
        return;
      }
      if (typeof group_id == undefined) {
        return;
      }
      var params = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: stage_id,
        group_id: group_id,
        type: type || 3,
      };
      GetStates(params).then((response) => {
        if (response.data.code === 0) {
          // self.$Message.success('保存成功');
          // todo 生成积分榜或者对阵图
          var _key = String(stage_id) + "-" + String(group_id);
          if (type === 1) {
            self.$set(self.table_matches, _key, response.data.data.table || []);
          } else if (type === 2) {
            self.$set(self.table_matches, _key, response.data.data.map || []);
          } else {
            if (
              response.data.data.table &&
              response.data.data.table.length > 0
            ) {
              self.$set(
                self.table_matches,
                _key,
                response.data.data.table || []
              );
            } else if (
              response.data.data.map &&
              response.data.data.map.length > 0
            ) {
              self.$set(self.table_matches, _key, response.data.data.map || []);
            } else {
              self.$set(self.table_matches, _key, []);
            }
          }
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    updateBoardScore(stage_id, group_id){
      this.getStates(stage_id, group_id, 1);
    },
    // end 
    
    stageAndGroupChange() {
      let self = this;
      var _key =
        String(self.board_stage_id) + "-" + String(self.board_group_id);
      if (!self.table_matches[_key]) {
        self.getStates(self.board_stage_id, self.board_group_id);
      }
    },
    // end
    listenRefresh(data) {
      let self = this;
      // console.log( "refresh: ", data );
      self.getStates(self.board_stage_id, self.board_group_id, 2);
    },
    // end
    beforeTabStageRemove(index) {
      let self = this;
      const stage = self.stages[index];
      // console.log(index, stage);

      return new Promise(function (resolve, reject) {
        const params = {
          game_id: self.game_id,
          tournament_id: self.tournament_id,
          stage_id: stage.id,
        };
        DelStage(params).then((response) => {
          if (response.data.code === 0) {
            resolve(stage.id);
          } else {
            self.$Message.warning(response.data.msg);
            reject();
          }
        });
        // self.$Modal.confirm({
        //       title: '确认删除',
        //       content: '<p>当前tab下内容奖杯删除 且不可恢复</p><p>是否确认删除?</p>',
        //       onOk: () => {
        //           resolve( stage.id );
        //       },
        //       onCancel: () => {
        //           reject();
        //       }
        //   });
      });
    },
    handleTabStageRemove(stage_id) {
      let self = this;
      // console.log( stage_id );
      // self.stages.splice(  )
      self.getTournamentStages();
    },
    beforeTabGroupRemove(index) {
      let self = this;
      // const stage = self.stages[index];
      const group = self.stage_groups[self.board_stage_id][index];
      // console.log(index, group)
      return new Promise(function (resolve, reject) {
        var params = {
          game_id: self.game_id,
          tournament_id: self.tournament_id,
          stage_id: self.board_stage_id,
          group_id: group.group_id,
          type: group.type,
        };
        // console.log(params)
        DelStage(params).then((response) => {
          // console.log(response.data)
          if (response.data.code === 0) {
            resolve();
          } else {
            self.$Message.warning(response.data.msg);
            reject();
          }
        });
        // 执行删除操作
        // self.$Modal.confirm({
        //     title: '确认删除',
        //     content: '<p>当前tab下内容奖杯删除 且不可恢复</p><p>是否确认删除?</p>',
        //     onOk: () => {
        //         resolve( group.id, params );
        //     },
        //     onCancel: () => {
        //         reject();
        //     }
        // });

        // if (index <= 0){
        //   self.$Message.warning('不可删除！');
        //   reject();
        // }else{
        //   // 确认框
        //   self.$Modal.confirm({
        //       title: '确认删除',
        //       content: '<p>当前tab下内容奖杯删除 且不可恢复</p><p>是否确认删除?</p>',
        //       onOk: () => {
        //           // this.$Message.info('Clicked ok');
        //           resolve(index);
        //       },
        //       onCancel: () => {
        //           // this.$Message.info('Clicked cancel');
        //           reject();
        //       }
        //   });
        // }
      });
    },
    handleTabGroupRemove(group_id, data) {
      let self = this;
      // console.log( group_id, data );
      self.getTournamentStages();
    },
    // end
    getStageMatch() {
      let self = this;
      var params = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: self.board_stage_id,
        group_id: self.board_group_id,
      };
      const _key =
        String(self.board_stage_id) + "-" + String(self.board_group_id);
      GetStageMatches(params).then((response) => {
        if (response.data.code === 0) {
          // console.log(_key, response.data.data.list );
          self.$set(self.stage_matches, _key, response.data.data.list);
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    onStageMatchClick(tab_name) {
      let self = this;
      const _key =
        String(self.board_stage_id) + "-" + String(self.board_group_id);
      // console.log( tab_name, _key );
      if (tab_name === 1) {
        if (!(_key in self.stage_matches)) {
          // 请求接口获取赛程数据
          self.getStageMatch();
        }
      }
    },
    // end
    addStageMatchModal() {
      // 添加赛程的模态框
      let self = this;
      self.new_stage_match = {
        id: 0,
        match_id: 0,
        match_ids: [],
      };
      self.modal_add_stage_match = true;
    },
    // end
    apiAddStageMatch() {
      let self = this;
      var params = {
        game_id: self.game_id,
        tournament_id: self.tournament_id,
        stage_id: self.board_stage_id,
        group_id: self.board_group_id,
        match_ids: self.new_stage_match.match_ids,
      };
      AddStageMatches(params).then((response) => {
        if (response.data.code === 0) {
          self.$Message.success("保存成功");
          // 重新获取榜单
          self.getStageMatch();
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    apiEditStageMatch() {
      let self = this;
      var params = {
        id: self.new_stage_match.id,
        kvs: {
          match_id: self.new_stage_match.match_id,
        },
      };
      UpdateStageMatches(params).then((response) => {
        if (response.data.code === 0) {
          self.$Message.success("保存成功");
          // 重新获取榜单
          self.getStageMatch();
        } else {
          self.$Message.error(response.data.msg);
        }
      });
    },
    // end
    add_to_stage_match_ids(match_id) {
      let self = this;
      var match_id = match_id || self.new_stage_match.match_id;
      if (
        parseInt(match_id) > 0 &&
        self.new_stage_match.match_ids.indexOf(match_id) === -1
      ) {
        // console.log(match_id, self.new_stage_match.match_ids.indexOf( match_id ))
        self.new_stage_match.match_ids.push(match_id);
        if (match_id == self.new_stage_match.match_id) {
          self.new_stage_match.match_id = 0;
        }
      }
    },
    // end
    del_from_stage_match_ids(event, name) {
      const index = this.new_stage_match.match_ids.indexOf(name);
      this.new_stage_match.match_ids.splice(index, 1);
    },
    // end
    matchRowClicked(row) {
      let self = this;
      // console.log(row);
      // 填充到日志编辑里
      const match_id = row.id;
      if (match_id) {
        self.add_to_stage_match_ids(match_id);
      }
    },
    // end
  },
  mounted() {
    let self = this;
    this.game_id = this.$route.query.game_id;
    this.tournament_id = this.$route.query.tournament_id;
    self.hookReplaceAll();
    self.getDataDetail();
    self.getDataMatches();
    self.getTournamentStages();
  },
  watch: {
    board_stage_id(value) {
      let self = this;
      // console.log("board_stage_id", value);
      // var _key = String( self.board_stage_id ) +'-'+ String( self.board_group_id );
      // var has_group = false;
      // var start_group_id = null;
      // console.log( self.stage_groups[parseInt( self.board_stage_id )] )
      var new_board_group_id = "";
      for (var _index in self.stage_groups[parseInt(self.board_stage_id)]) {
        var group = self.stage_groups[parseInt(self.board_stage_id)][_index];
        // console.log(group)
        if (group.group_id >= 0) {
          new_board_group_id = String(group.group_id);
          break;
        }
      }
      // console.log("test: ", new_board_group_id, self.board_group_id)
      if (new_board_group_id != self.board_group_id) {
        self.board_group_id = new_board_group_id;
      } else {
        self.stageAndGroupChange();
      }
    },
    //end
    board_group_id(value) {
      let self = this;
      self.stageAndGroupChange();
    },
    //end
  },
  computed: {
    match_time: function () {
      let self = this;
      if (self.data) {
        return moment.unix(self.data.match_time).format("YYYY-MM-DD HH:mm");
      }
    },
    tournament_start_date: function () {
      let self = this;
      if (self.tournament) {
        return moment.unix(self.tournament.start_date).format("YYYY-MM-DD");
      }
    },
    tournament_end_date: function () {
      let self = this;
      if (self.tournament) {
        return moment.unix(self.tournament.end_date).format("YYYY-MM-DD");
      }
    },
  },
};
</script>
