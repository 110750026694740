import http from '@/utils/http';

export const GameRoundInfo = (parameter) => {
  return http.request({
    url: '/v1/games/round/info',
    method: 'get',
    params: parameter
  })
}

export const GameRoundSearch = (parameter) => {
  return http.request({
    url: '/v1/games/round/search',
    method: 'get',
    params: parameter
  })
}



export const GetRound = (parameter) => {
  return http.request({
    url: '/v1/games/round/list',
    method: 'get',
    params: parameter
  })
}

export const AddRound = (parameter) => {
  return http.request({
    url: '/v1/games/round/add',
    method: 'post',
    data: parameter
  })
}

export const DelRound = (parameter) => {
  return http.request({
    url: '/v1/games/round/del',
    method: 'post',
    data: parameter
  })
}

export const UpdateRound = (parameter) => {
  return http.request({
    url: '/v1/games/round/update',
    method: 'post',
    data: parameter
  })
}
