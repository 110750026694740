<template>
  <div>
    <Input type="text" v-model="match_id" number placeholder="赛程id"></Input>
    <p>{{info}}</p>
    <row>
      <Input type="text" v-model="team_name" number placeholder="队伍名称"></Input>
      <Date-picker
          :value="date"
          format="yyyy-MM-dd"
          type="date"
          placeholder="创建时间"
          @on-change="date=$event"
      ></Date-picker>
      <Button type="primary" icon="ios-search" @click="searchMatch">搜索赛程</Button>
      <row>
          <RadioGroup v-model="match_id" vertical @on-change="onRadioChange" >
              <Radio :label="item.id" v-for="item in matchList" :key="item">
                  <span>{{ item.desc }}</span>
              </Radio>
          </RadioGroup>
      </row>
    </row>
  </div>
</template>
<script>
// import TournamentSelector from "../components/tournament-selector.vue";
import moment from 'moment'
import { GameMatchInfo, GameMatchSearch } from '@/api/games/match'

export default {
  name: 'match-finder',
  props: ['value', 'game_id', 'tournament_id', "match"],
  components: {
    // TournamentSelector
  },
  data () {
    return {
      match_id: this.value,
      team_name: null,
      date: moment().format('YYYY-MM-DD'),
      matchList: [],
      info: ''
    }
  },
  watch: {
    match_id (val) {
      this.getMatchInfo()
      this.$emit('input', val)
    },
    game_id (val) {
      this.$emit('on_game_id_update', val)
    },
    value (val) {
      this.match_id = val
    }
  },
  mounted () {
    this.match_id = this.value
    this.getMatchInfo();
  },
  methods: {
    getMatchInfo () {
      let self = this
      if (self.match_id) {
        // console.log(111);
        GameMatchInfo({ match_id: self.match_id, game_id: self.game_id })
          .then(response => {
            if (response.data.info) {
              let home_team_name = response.data.info.home_team_name;
              let away_team_name = response.data.info.away_team_name;
              if (home_team_name) {
                let time = moment.unix(response.data.info.match_time).format('YYYY-MM-DD HH:mm')
                self.info = `${time} ${home_team_name} VS ${away_team_name}`
              } else {
                self.info = ''
              }
              self.match.start_time = response.data.info.match_time;
              self.match.home = {
                id: response.data.info.home_team_id,
                name: response.data.info.home_team_name,
                logo: response.data.info.home_team_logo,
                score: response.data.info.home_team_score,
              }
              self.match.away = {
                id : response.data.info.away_team_id,
                name : response.data.info.away_team_name,
                logo : response.data.info.away_team_logo,
                score : response.data.info.away_team_score,
              }
            } else {
              self.info = '';
              self.match.start_time = 0;
            }
          })
      } else {
        self.info = ''
      }
    },
    searchMatch () {
      let self = this
      if (self.game_id || self.tournament_id || self.date || self.team_name) {
        // self.axios
        //   .get("/v1/match/search", {
        //     params: {
        //       game_id: self.game_id,
        //       tournament_id: self.tournament_id,
        //       date: self.date,
        //       team_name: self.team_name
        //     }
        //   })
        GameMatchSearch({
          game_id: self.game_id,
          tournament_id: self.tournament_id,
          date: self.date,
          team_name: self.team_name
        })
          .then(response => {
            // console.log(response)
            if (response.data.code == 0) {
              self.matchList = response.data.list
            }
          })
      }
    },
    // end
    onRadioChange(e){
        let self = this;
        // console.log(e);
        // for (var _index in self.matchList){
        //     const match = self.matchList[_index];
        //     // console.log(match)
        //     if (match.id === e){
        //         self.home.id = match.home.id;
        //         self.home.name = match.home.name;
        //         self.home.logo = match.home.logo;
        //         self.home.score = match.home.score;
        //         //
        //         self.away.id = match.away.id;
        //         self.away.name = match.away.name;
        //         self.away.logo = match.away.logo;
        //         self.away.score = match.away.score;
        //         // self.away = match.away;
        //         // self.start_time = match.match_time;
        //         break;
        //     }
        // }
    },
    // end
  }
}
</script>
