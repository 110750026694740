import http from '@/utils/http';

export const List = (parameter) => {
  return http.request({
    url: '/v1/tournament/list',
    method: 'get',
    params: parameter
  })
}

export const editTournament = (parameter) => {
  return http.request({
    url: '/v1/games/tournament/edit',
    method: 'post',
    data: parameter
  })
}

export const searchTournament = (parameter) => {
  return http.request({
    url: '/v1/games/tournament/search',
    method: 'post',
    data: parameter
  })
}

export const TournamentDetail = (parameter) => {
  return http.request({
    url: '/v1/tournament/detail',
    method: 'get',
    params: parameter
  })
}

export const TournamentMatches = (parameter) => {
  return http.request({
    url: '/v1/tournament/matches',
    method: 'get',
    params: parameter
  })
}

export const TournamentStages = (parameter) => {
  return http.request({
    url: '/v1/tournament/states_group_menu',
    method: 'get',
    params: parameter
  })
}


export const CacheStageAdd = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage/cache_add',
    method: 'post',
    data: parameter,
  })
}

export const CacheGroupAdd = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage/cache_add_group',
    method: 'post',
    data: parameter,
  })
}

export const SaveGroupConf = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage/save_conf',
    method: 'post',
    data: parameter,
  })
}


export const GetStates = (parameter) => {
  return http.request({
    url: '/v1/tournament/states',
    method: 'get',
    params: parameter
  })
}

export const AddBoardTeam = (parameter) => {
  return http.request({
    url: '/v1/tournament/boards/add_team',
    method: 'post',
    data: parameter,
  })
}

export const DelBoardTeam = (parameter) => {
  return http.request({
    url: '/v1/tournament/boards/del_team',
    method: 'post',
    data: parameter,
  })
}

export const EditBoardTeam = (parameter) => {
  return http.request({
    url: '/v1/tournament/boards/edit_team',
    method: 'post',
    data: parameter,
  })
}

export const updateBoardTeamOrder = (parameter) => {
  return http.request({
    url: '/v1/tournament/boards/update_order',
    method: 'post',
    data: parameter,
  })
}

export const GetDemo = (parameter) => {
  return http.request({
    url: '/v1/tournament/maps/demo',
    method: 'post',
    data: parameter
  })
}

export const SaveStates = (parameter) => {
  return http.request({
    url: '/v1/tournament/save_states',
    method: 'post',
    data: parameter,
  })
}

export const DelStage = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage/del',
    method: 'post',
    data: parameter,
  })
}


export const GetStageMatches = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage_matches/list',
    method: 'get',
    params: parameter
  })
}

export const AddStageMatches = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage_matches/add',
    method: 'post',
    data: parameter,
  })
}

export const DelStageMatches = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage_matches/del',
    method: 'post',
    data: parameter,
  })
}

export const UpdateStageMatches = (parameter) => {
  return http.request({
    url: '/v1/tournament/stage_matches/update',
    method: 'post',
    data: parameter,
  })
}


// 赛果
export const getResults = (parameter) => {
  return http.request({
    url: '/v1/tournament/result/list',
    method: 'get',
    params: parameter
  })
}

export const updateResult = (parameter) => {
  return http.request({
    url: '/v1/tournament/result/update',
    method: 'post',
    data: parameter,
  })
}
