<template>
    <Row style="background: #FFF; "   >
        <Modal v-model="edit_modal" title="编辑比赛" @on-ok="updateMatch">
          <Form :label-width="150" style="margin-top:1px;" @submit.native.prevent>
              <FormItem label="ID">
                  <Input v-model="edit_match.id" disabled></Input>
              </FormItem>
              <FormItem label="坐标值(左上角)" >
                <Row>
                    <Col span = '1'>
                      (x)=:
                    </Col>
                    <Col span='6'>
                      <Input v-model="edit_match.round" type='number' number></Input>
                    </Col>
                    <Col span='1' push=2>
                      (y)=:
                    </Col>
                    <Col span='6' push=2>
                      <Input v-model="edit_match.sequence" type='number' number></Input>
                    </Col>
                </Row>
              </FormItem>
              <FormItem label="所属类别:" >
                  <Select v-model="edit_match.sequence_type" >
                      <Option :value="1">胜者组</Option>
                      <Option :value="2">败者组</Option>
                      <Option :value="0">决赛</Option>
                  </Select>
              </FormItem>
              <FormItem label="是否轮空">
                  <Checkbox v-model="edit_match.is_empty"></Checkbox>
              </FormItem>
              <FormItem label="搜索比赛" v-if="!edit_match.is_empty">
                  <MatchFinder v-model="edit_match.match_id"
                      :game_id="game_id"
                      :tournament_id="tournament_id"
                      :match = "edit_match"
                    >
                  </MatchFinder>
              </FormItem>
              <FormItem label="是否晋级">
                  <Checkbox v-model="is_promotion"></Checkbox>
              </FormItem>
              <FormItem label="晋级名称(晋级/冠军)" v-if="is_promotion">
                  <Input v-model="edit_match.promot_name"></Input>
              </FormItem>

              <FormItem label="比赛轮次" >
                  <!-- <Input v-model="edit_match.round_id"></Input> -->
                  <RoundFinder
                      v-model="edit_match.round_id"
                      :game_id="game_id"
                      :round_name="edit_match.round_name"
                      v-on:listenRoundidUpdate="listenRoundidUpdate"
                  >
                  </RoundFinder>
              </FormItem>

              <FormItem label="更新其余同名比赛轮次" v-if="edit_match.id" >
                <Checkbox v-model="update_to_nodes"></Checkbox>
              </FormItem>
          </Form>
        </Modal>
        <!-- <Button type="info" style="text-align: right;" @click="getGraph()">信息</Button> -->
        <Button type="success" style="text-align: right;" @click="addNode()">新增节点</Button>
        <Button type="info" style="text-align: right;" @click="saveToDb()">结束编辑并保存</Button>
        <div :id="createNodeId()" style="background: #EEE5; height:700px; width:100%"></div>
    </Row>
</template>

<script>

import G6 from '@antv/g6';
import moment from 'moment';
import MatchFinder from './finder.vue';
import RoundFinder from '../round/finder.vue';
import { GetDemo, SaveStates } from '@/api/games/tournament';

export default {
  name: 'match_map',
  props: ['value', "stage_id", "group_id", "conf", "sub_type", "teams", "third",
          'game_id', 'tournament_id',],
  data() {
    return {
        data_list: [],
        graph: null,
        team_width : 200,
        team_height : 50,
        banner_height : 2,
        match_height : 30,
        demo_data: {
          // 点集
          nodes: [],
          // 边集
          edges: [{
            source: 'node1', // String，必须，起始点 id
            target: 'node3',  // String，必须，目标点 id
          }]
        },
        // end
        edit_match: {  // 编辑的比赛
        },
        edit_modal: false,  // 编辑比赛的模态框
        update_to_nodes: true,  // 更新到所有的节点
        max_win_sequences: 0,
    }
  },
  created() {
  },
  watch:{
      value (new_value) {
          let self = this;
          // console.log(111);
          self.data_list = new_value || [];
          // if (new_value && new_value.length > 0){
          //     self.$set( self.data_list, 0, new_value[0] );
          // }else if (self.data_list && self.data_list.length > 0){
          //     self.data_list.splice( 0, self.data_list.length )
          // }
          // console.log(self.data_list);
          self.initMap();
      },
      sub_type(value){
          let self = this;
          self.initMap();
      },
      teams(value){
          let self = this;
          self.initMap();
      },
      third(value){
          let self = this;
          self.initMap();
      },
      // data_list(value){
      //     console.log(2222);
      // },
  },
  mounted () {
      let self = this;
      self.data_list = self.value || [];
      self.registMatchLine();
      self.registMatchNode();
      self.initMap();
  },
  components: {
      MatchFinder,
      RoundFinder,
  },
  methods: {
      createNodeId(){
          let self = this;
          return "mountNode_" + String( self.stage_id ) + "_" + String( self.group_id );
      },

      registMatchLine(){
        G6.registerEdge(
          'line-arrow',
          {
            getPath(points) {
              const startPoint = points[0];
              const endPoint = points[1];
              return [
                ['M', startPoint.x, startPoint.y],
                // ['L', endPoint.x / 2 + 1 * startPoint.x, startPoint.y],
                // ['L', endPoint.x / 2 + 1 * startPoint.x, endPoint.y],
                ['L', startPoint.x + 0.5*( endPoint.x - startPoint.x), startPoint.y],
                ['L', startPoint.x + 0.5*( endPoint.x - startPoint.x), endPoint.y],
                ['L', endPoint.x, endPoint.y],
              ];
            },
            getShapeStyle(cfg) {
              const startPoint = cfg.startPoint;
              const endPoint = cfg.endPoint;
              const controlPoints = this.getControlPoints(cfg);
              let points = [startPoint]; // the start point
              // the control points
              if (controlPoints) {
                points = points.concat(controlPoints);
              }
              // the end point
              points.push(endPoint);
              const path = this.getPath(points);
              const style = Object.assign(
                {},
                G6.Global.defaultEdge.style,
                {
                  stroke: '#BBB',
                  lineWidth: 1,
                  path,
                },
                cfg.style,
              );
              return style;
            },
          },
          'line',
        );
      },

      registMatchNode(){
        let self = this;
        G6.registerNode('match-node',
        {
          drawShape: function drawShape(cfg, group) {
              //最外面的那层
              const color = cfg.error ? '#F4664A' : '#30BF78';
              const r = 0;
              const is_empty = cfg.is_empty;  // 是否轮空
              const team_width = self.team_width;
              const team_height = self.team_height;
              const banner_height = 2;
              const match_x = cfg.x;
              const match_y = cfg.y;
              const match_height = self.match_height;
              const home_x = match_x;
              const home_y = match_y + match_height ;
              const away_x = home_x;
              const away_y = home_y + team_height + 1;
              const row_id = cfg.id || '';
              const match_id = cfg.match_id || '';
              const match_time = cfg.start_time? moment.unix(cfg.start_time).format('YYYY-MM-DD HH:mm'):'';
              const round = cfg.round;
              const sequence = cfg.sequence;
              const is_promotion = cfg.is_promotion;
              const promot_name = cfg.promot_name;
              const round_name = cfg.round_name;
              // console.log(promot_name);
              var home_team = cfg.home || {};
              var away_team = cfg.away || {};
              cfg.anchorPoints = [
                [0, 0.5],
                [1, 0.5],
              ];
              const shape = group.addShape('rect', {
                draggable: false,
                name: 'match-box',
                attrs: {
                  x: match_x,
                  y: match_y,
                  width: team_width,
                  height: match_height + team_height * 2,
                  radius: 0,
                  fill: "#F4664A",
                },
              });
              if (is_empty){
                  group.addShape('rect', {
                    draggable: false,
                    name: 'match-box',
                    attrs: {
                      x: match_x,
                      y: match_y,
                      width: team_width,
                      height: match_height + team_height * 2,
                      radius: 0,
                      fill: "#AAA",
                    },
                  });
                  group.addShape('text', {
                    draggable: false,
                    attrs: {
                      // textBaseline: 'center',
                      x: match_x + 3,
                      y: match_y + match_height,
                      lineHeight: 20,
                      fontSize: 14,
                      text: "轮空",  // 比赛id
                      fill: '#FFF',
                    },
                  });
              }else{
                  //文字
                  group.addShape('text', {
                    draggable: false,
                    attrs: {
                      // textBaseline: 'center',
                      x: match_x + 3,
                      y: match_y + match_height,
                      lineHeight: 30,
                      fontSize: 24,
                      text: "("+round+","+sequence+")",  // 坐标
                      fill: '#FFF',
                    },
                  });
                  group.addShape('text', {
                    draggable: false,
                    attrs: {
                      // textBaseline: 'center',
                      x: match_x + team_width * 0.5,
                      y: match_y + match_height,
                      lineHeight: 20,
                      fontSize: 20,
                      text: match_id,  // 比赛id
                      fill: '#FFF',
                    },
                  });
                  group.addShape('text', {
                    draggable: false,
                    attrs: {
                      // textBaseline: 'center',
                      x: home_x  ,
                      y: home_y - match_height  ,
                      lineHeight: 20,
                      fontSize: 18,
                      text: match_time,  // 比赛id
                      fill: '#000',
                    },
                  });
                  if (round_name){
                    group.addShape('text', {
                      draggable: false,
                      attrs: {
                        // textBaseline: 'center',
                        x: home_x ,
                        y: home_y - match_height - 20 ,
                        lineHeight: 20,
                        fontSize: 18,
                        text: round_name,  // 比赛id
                        fill: '#000',
                      },
                    });
                  }

                  group.addShape('rect', {
                    draggable: false,
                    name: 'home-box',
                    attrs: {
                      x: home_x,
                      y: home_y,
                      width: team_width,
                      height: team_height,
                      stroke: '', //边框
                      radius: r,
                      fill: "#F4664A",
                    },
                  });
                  group.addShape('rect', {
                    draggable: false,
                    name: 'home-box-inside',
                    attrs: {
                      x: home_x+ 1,
                      y: home_y+ banner_height,
                      width: team_width - 2,
                      height: team_height - banner_height -1,
                      stroke: '', //边框
                      radius: r,
                      fill: "#FFF",
                    },
                  });
                  //客队外框
                  group.addShape('rect', {
                    draggable: false,
                    name: 'away-box',
                    attrs: {
                      x: away_x,
                      y: away_y,
                      width: team_width,
                      height: team_height,
                      fill: '#30BF78', //填充色
                      stroke: '', //边框
                      radius: r,
                    },
                  });
                  group.addShape('rect', {
                    draggable: false,
                    name: 'away-box-inside',
                    attrs: {
                      x: away_x +1,
                      y: away_y +banner_height,
                      width: team_width-2,
                      height: team_height-banner_height-1,
                      fill: '#FFF', //填充色
                      stroke: '', //边框
                      radius: r,
                    },
                  });
                  // 主队logo
                  group.addShape('image', {
                      draggable: false,
                      name: 'home-icon',
                      attrs: {
                        x: home_x ,
                        y: home_y + banner_height,
                        height: team_height - banner_height ,
                        width:  team_height - banner_height ,
                        cursor: 'pointer',
                        img: home_team.logo || '',
                      },
                  });
                  group.addShape('image', {
                      draggable: false,
                      name: 'away-icon',
                      attrs: {
                        x: away_x ,
                        y: away_y + banner_height,
                        height: team_height - banner_height,
                        width:  team_height - banner_height,
                        cursor: 'pointer',
                        img: away_team.logo || '',
                      },
                  });

                  group.addShape('text', {
                      draggable: false,
                      name: 'away-desc',
                      attrs: {
                        x: home_x + team_height + banner_height,
                        y: home_y + team_height - 10,
                        lineHeight: 20,
                        fontSize: 13,
                        text: (home_team.name || '')+ '   ' + (home_team.score || '0'),
                        fill: '#666',
                      },
                  });
                  group.addShape('text', {
                      draggable: false,
                      name: 'home-desc',
                      attrs: {
                        // textBaseline: 'center',
                        x: away_x + team_height + banner_height ,
                        y: away_y + team_height - 10,
                        lineHeight: 20,
                        fontSize: 13,
                        text: (away_team.name || '')+ '   ' + (away_team.score|| '0'),
                        fill: '#666',
                      },
                  });

                  if(is_promotion){
                      group.addShape('rect', {
                        draggable: false,
                        name: 'promotion-box',
                        attrs: {
                          x: away_x + team_width / 4,
                          y: away_y + team_height,
                          width: team_width/2,
                          height: 20,
                          fill: '#2db7f5', //'#0F06', //填充色
                          stroke: '', //边框
                          radius: r,
                        },
                      });
                      if (promot_name){
                          group.addShape('text', {
                              draggable: false,
                              name: 'promotion-name',
                              attrs: {
                                // textBaseline: 'center',
                                x: away_x + team_width / 4 + 20 ,
                                y: away_y + team_height + 20,
                                lineHeight: 20,
                                fontSize: 16,
                                text: promot_name,
                                fill: '#FFF',
                              },
                          });
                      }
                  }
              }
            return shape;
          },
          });
      },

      // end
      // end
      demoGraph( ){
          let self = this;
          var container_id = 'mountNode_'+ String( self.stage_id ) + "_" + String( self.group_id );
          const contextMenu = new G6.Menu({
              getContent(evt) {
                  let header;
                  if (evt.target && evt.target.isCanvas && evt.target.isCanvas()) {
                    header = 'Canvas ContextMenu';
                  } else if (evt.item) {
                    const itemType = evt.item.getType();
                    // console.log(itemType, evt.item);
                    // header = `${itemType.toUpperCase()} `;
                    if (itemType == 'node'){
                        return `
                        <h4>节点操作</h4>
                        <ul style="
                              list-style-type: none;
                              margin: 10px;
                        ">
                          <li title='1' style="margin: 10px;" >编辑</li>
                          <li title='2' style="margin: 10px;" >删除</li>
                        </ul>`;
                    }else if (itemType == 'edge'){
                        return `
                        <h3>边操作</h3>
                        <ul style="
                              list-style-type: none;
                              margin: 10px;
                        ">
                          <li title='2' style="margin: 10px;" >删除</li>
                        </ul>`;
                    }
                  }

              },
              handleMenuClick: (target, item) => {
                  // console.log(target, item);
                  const title= target.getAttribute('title') || '0';
                  const item_type = item.getType();
                  // console.log( title, item_type, item);
                  if (item_type == 'node'){
                      // 1 编辑节点
                      // 2 删除节点
                      if (title === '1'){
                          // 编辑比赛
                          self.edit_match = JSON.parse(JSON.stringify( item._cfg.model ));
                          self.edit_modal = true;
                      }
                      else if (title === '2'){
                          self.graph.removeItem(item);
                      }
                  } else if (item_type == 'edge'){
                      if (title === '2'){
                          // 删除线
                          const line_model = item._cfg.model;
                          const source = line_model.source;
                          const target = line_model.target;
                          var all_edges = self.graph.getEdges();
                          // console.log(all_edges);
                          all_edges.forEach(function(edge,index){
                              if ( ! edge.desyroyed ){
                                  var edge_conf = edge._cfg;
                                  // console.log(edge_conf);
                                  if (edge_conf){
                                      var edge_model = edge_conf.model;
                                      if (edge_model.source === source && edge_model.target === target){
                                          // self.graph.refresh(); // 报错
                                          self.graph.removeItem( edge );
                                      }
                                  }
                              }
                          })
                      }
                  }
              },
              // offsetX and offsetY include the padding of the parent container
              // 需要加上父级容器的 padding-left 16 与自身偏移量 10
              offsetX: 0,
              // 需要加上父级容器的 padding-top 24 、画布兄弟元素高度、与自身偏移量 10
              offsetY: 0,
              // the types of items that allow the menu show up
              // 在哪些类型的元素上响应
              itemTypes: ['node', 'edge'],  // 'canvas'
            });
          // console.log(container_id);
          //获取高度
          var h = document.getElementById(container_id).clientHeight;
          //获取宽度
          var w = document.getElementById(container_id).clientWidth;
          // console.log(w, h);
          self.graph = new G6.Graph({
              container: container_id,  // String | HTMLElement，必须，在 Step 1 中创建的容器 id 或容器本身
              width: w,              // Number，必须，图的宽度
              height: h,              // Number，必须，图的高度
              // autoPaint: true,
              fitView: true,
              modes: {
                  default: [ "drag-canvas", "click-select", 'zoom-canvas',
                    {
                      type: 'create-edge',
                      trigger: 'drag', // 'click' by default. options: 'drag', 'click'
                    },]
              },
              defaultNode: {
                type: "match-node"
              },
              defaultEdge: {
                // type: 'polyline',
                type: 'line-arrow',
                /* you can configure the global edge style as following lines */
                style: {
                    stroke: '#000',
                    endArrow: {
                      path: 'M 0,0 L 12,6 L 9,0 L 12,-6 Z',
                      fill: '#000',
                    },
                },
              },
              plugins: [contextMenu],
          });
          self.graph.on('aftercreateedge', e => {
              // self.graph.getEdges().forEach(function(edge,index){
              //     var edge_conf = edge._cfg;
              //     // console.log(edge_conf);
              //     if (edge_conf){
              //         var edge_model = edge_conf.model;
              //         if (edge_model.source === edge_model.target){
              //             self.graph.removeItem( edge );
              //         }
              //     }
              // })
              self.calcNewCoor();
          });
          // 点击节点
          self.graph.on('node:dblclick', (e) => {
            // 先将所有当前是 click 状态的节点置为非 click 状态
            const item = e.item;
            self.edit_match =  JSON.parse(JSON.stringify( item._cfg.model ));
            self.edit_modal = true;
          });
          //
          self.graph.data(self.demo_data);  // 读取 Step 2 中的数据源到图上
          self.graph.render();    // 渲染图
      },
      // end
      // 根据data_list创建图
      initMap(){
          let self = this;
          const data_dic = self.data_list.length > 0?self.data_list[0]:{} ;
          var nodes = [];
          var edges = [];
          const _type = data_dic["type"];
          const raw_list = data_dic.list || [];
          const raw_line = data_dic.line || [];
          // 排序
          raw_list.sort( function(a,b){
              if (a.round !== b.round ){
                  return a.round - b.round;
              }else{
                  return a.sequence - b.sequence;
              }
          });
          var rounds = [];
          var sequences = [];
          var sequence_types = [];
          var line_relation = {}; // 存储的是下一个连线数据
          var num_to_id = {};
          var ordered_nums = [];
          var max_win_sequences = 0;
          var min_lose_sequences = null;

          for (var _index in raw_list){
              const match = raw_list[_index];
              if (! rounds.includes( match.round ) ){
                  rounds.push(match.round);
              }
              if (! sequences.includes (match.sequence) ){
                  sequences.push(match.sequence);
              }
              if ( sequence_types && ! sequence_types.includes (match.sequence_type )){
                  sequence_types.push(match.sequence_type);
              }
              const num = match.num;
              if ( typeof num != 'undefined'){
                  num_to_id[num] = match.id;
                  ordered_nums.push(num);
              }
              // 记录胜者组最大的sequence - 供败者组往下排序
              if (match.sequence_type==1){
                  max_win_sequences = max_win_sequences>= match.sequence?
                    max_win_sequences: match.sequence;
              }else if (match.sequence_type == 2){
                  if (min_lose_sequences === null){
                      min_lose_sequences = match.sequence;
                  }else{
                      min_lose_sequences = min_lose_sequences <= match.sequence?
                        min_lose_sequences: match.sequence;
                  }
              }
          }

          for (var _index in raw_line){
              const line = raw_line[_index];
              const start_num = line[0];
              const end_num = line[1][0];
              const start_num_index = ordered_nums.indexOf( start_num ) || 0;
              const end_num_index = ordered_nums.indexOf( end_num ) || 0;
              const start_num_id = num_to_id[start_num];
              const end_num_id = num_to_id[end_num];
              if ( start_num_index < end_num_index){
                  line_relation[ start_num_id ] = end_num_id;
              }else if (start_num_id > end_num_index){
                  line_relation[ end_num_id ] = start_num_id;
              }
          }
          if (!self.teams ){
              var teams = 2**rounds.length;
          } else{
              var teams = self.teams;
          }
          if (!self.sub_type ){
              if (sequence_types.length == 2){
                var sub_type = 2;
              }else{
                var sub_type = 1;
              }
          }else{
              var sub_type = self.sub_type;
          }

          if (sub_type == 1){
              if ( self.third === false || self.third === true ){
                  var third = self.third;
              }else{
                  var third = false;
              }
          }else{
              var third = false;
          }
          if ( (raw_list && raw_list.length > 0) || (sub_type>0 && parseInt(teams)%2===0) ){
              // console.log( 1, sub_type, teams )
              if (max_win_sequences < (min_lose_sequences || 0) ){
                  self.max_win_sequences = 0;
              }else{
                  self.max_win_sequences = max_win_sequences;
              }
              self.createTemplate(raw_list, sub_type, teams, third, line_relation);
          }
      },
      // end
      createTemplate( raw_list, sub_type, teams, third, line_relation){
          // 创建模板
          let self = this;
          var nodes = [];
          var params = {
              sub_type: sub_type,
              third: third?1:0,
              teams: teams,
              raw_list: raw_list,
              line_relation: line_relation,
          };
          // console.log(2, raw_list);
          if (raw_list && raw_list.length > 0){
              const start_x = 0;
              const start_y = 0;
              const box_width = self.team_width;
              const box_height = self.team_height * 2 + self.match_height;
              const x_dis = 50;
              const y_dis = 30;
              var nodes = [];
              var edges = [];
              var parent_edges = {};

              for (var source in line_relation){
                  edges.push({
                    source: source,
                    target: line_relation[source],
                  });
                  if ( !(line_relation[source] in parent_edges) ){
                      parent_edges[ line_relation[source] ] = [];
                  }
                  parent_edges[line_relation[source]].push( source );
              }
              var ys = {};
              var y_coors = {};
              for (var _index in raw_list){
                  var m = raw_list[_index];
                  m.x = start_x + ( m.round - 1) * ( box_width + x_dis );
                  var parent_ids = parent_edges[m.id];
                  if ( !parent_ids ){
                      if (m.sequence_type === 2){
                        if (m.sequence <= self.max_win_sequences){
                          var new_y1 = start_y + ( self.max_win_sequences+m.sequence) * ( box_height+y_dis );
                        }else{
                          var new_y1 = start_y + ( m.sequence ) * ( box_height+y_dis );
                        }
                      }else{
                          var new_y1 = start_y + ( m.sequence - 1) * ( box_height+y_dis );
                      }
                  }else{
                      var sum_parent_y = 0;
                      for (var i in parent_ids){
                          sum_parent_y += ys[ parent_ids[i] ] || 0;
                      }
                      var new_y1 = parseInt( sum_parent_y / parent_ids.length );
                  }
                  if ( !(m.sequence_type in y_coors ) ){
                      y_coors[m.sequence_type] = {}
                  }
                  if ( !(m.round in y_coors[m.sequence_type])){
                      y_coors[m.sequence_type][m.round] = 0;
                  }
                  var new_y2 = y_coors[m.sequence_type][m.round] + 1 * ( box_height + y_dis );
                  var new_y = new_y2 > new_y1 ? new_y2: new_y1;
                  // console.log( m.sequence_type, m.round, y_coors[m.sequence_type], new_y, m.id );
                  y_coors[m.sequence_type][m.round] = new_y>y_coors[m.sequence_type][m.round]? new_y:y_coors[m.sequence_type][m.round];

                  m.y = new_y;
                  ys[m.id] = new_y;
                  nodes.push( m );
              }
              self.demo_data.nodes = nodes;
              self.demo_data.edges = edges;
              if (self.graph){
                self.graph.destroy();
              }
              self.demoGraph();
          }else{

              GetDemo( params ).then( response => {
                  if (response.data.code === 0) {
                      self.demo_data.nodes = response.data.data.nodes;
                      self.demo_data.edges = response.data.data.edges;
                      if (self.graph){
                        self.graph.destroy();
                      }
                      self.demoGraph();
                  }else {
                      self.$Message.error(response.data.msg);
                  }
              })
          }
      },

      // // end
      // getGraph(){
      //     let self = this;
      //     console.log(self.graph);
      //     console.log('edges: ', self.graph.getEdges());
      //     console.log('nodes: ', self.graph.getNodes());
      // },
      // end
      addNode(){
          let self = this;
          self.edit_match = JSON.parse(JSON.stringify({
              home: {},
              away: {},
              x: 0,
              y: 0,
              anchorPoints: [
                  [0, 0.5],
                  [1, 0.5],
              ],
              sequence_type: 1,
          }));
          self.edit_modal = true;
      },
      // end
      updateMatch(){
          let self = this;
          // console.log(self.edit_match);
          if (self.edit_match.id){
              if  (self.edit_match.is_empty){
                  // 轮空
                  self.edit_match.start_time = null;
                  self.edit_match.match_id = 0;
                  self.edit_match.home = {};
                  self.edit_match.away = {};
              }
              // 获取原始数据
              const raw_node = self.graph.findById( self.edit_match.id );
              const raw_node_model = raw_node._cfg.model;

              const raw_round = raw_node_model.round;
              const raw_sequence = raw_node_model.sequence;
              const new_round = self.edit_match.round;
              const new_sequence = self.edit_match.sequence;

              if (self.update_to_nodes){
                const raw_round_id = raw_node_model.round_id;
                const raw_round_name = raw_node_model.round_name;
                const new_round_id = self.edit_match.round_id;
                const new_round_name = self.edit_match.round_name;
                if ( raw_round_id && raw_round_id !== new_round_id){
                    // 获取全部的节点 - 更新轮次
                    // const nodes = self.graph.getNodes();
                    self.graph.getNodes().forEach(function(node,index){
                        if ( ! node.desyroyed ){
                            var node_conf = node._cfg;
                            // console.log(edge_conf);
                            if (node_conf ){
                                var node_model = node_conf.model;
                                if (node_model.round_id === raw_round_id){
                                  node_model.round_id = new_round_id;
                                  node_model.round_name = new_round_name;
                                  self.graph.updateItem( node_model.id, node_model);
                                }
                            }
                        }
                    })
                }
              }
              self.graph.updateItem( self.edit_match.id, self.edit_match);
              //
              if (raw_round !== new_round || raw_sequence !== new_sequence){
                  // 重新计算坐标系生成图
                  self.calcNewCoor();
              }
          }else{
              self.edit_match.id = String(Math.random()); // 随机id
              self.edit_match.add = true; // 新增标记
              self.graph.addItem('node', self.edit_match);
              self.calcNewCoor();
          }
          self.saveToCache();
      },
      // end
      saveToCache(){
          let self = this;
      },
      // end
      // 监听的变化
      listenRoundidUpdate(value){
          let self = this;
          // console.log(value);
          const round_dic = JSON.parse( value );
          self.edit_match.round_id = round_dic.round_id || 0;
          self.edit_match.round_name = round_dic.round_name || '';
      },
      // end
      // 重新计算一下各个节点的坐标位置
      calcNewCoor(){
          let self = this;
          const current_nodes = self.graph.getNodes();
          var all_edges = self.graph.getEdges();
          // console.log(all_edges);
          var raw_list = [];
          // 获取所有节点的原始数据
          var max_win_sequences = 0;
          var min_lose_sequences = null;
          current_nodes.forEach(function(node,index){
              if ( ! node.desyroyed ){
                  var node_conf = node._cfg;
                  // console.log(edge_conf);
                  if (node_conf ){
                      var node_model = node_conf.model;
                      raw_list.push(node_model);
                      if (node_model.sequence_type == 1){
                          max_win_sequences = max_win_sequences>= node_model.sequence?
                            max_win_sequences: node_model.sequence;
                      }else if (node_model.sequence_type == 2){
                          if (min_lose_sequences === null){
                              min_lose_sequences = node_model.sequence;
                          }else{
                              min_lose_sequences = min_lose_sequences <= node_model.sequence?
                                min_lose_sequences: node_model.sequence;
                          }
                      }
                  }
              }
          });
          if (max_win_sequences < (min_lose_sequences || 0)){
              self.max_win_sequences = 0;
          }else{
              self.max_win_sequences = max_win_sequences;
          }
          // console.log(max_win_sequences, min_lose_sequences, self.max_win_sequences);
          // 节点排序
          raw_list.sort( function(a,b){
              if (a.round !== b.round ){
                  return a.round - b.round;
              }else{
                  return a.sequence - b.sequence;
              }
          });
          // 重新计算坐标值
          const start_x = 0;
          const start_y = 0;
          const box_width = self.team_width;
          const box_height = self.team_height * 2 + self.match_height;
          const x_dis = 50;
          const y_dis = 30;

          // var nodes = [];
          var parent_edges = {};

          all_edges.forEach(function(edge,index){
              if ( ! edge.desyroyed ){
                  var edge_conf = edge._cfg;
                  // console.log(edge_conf);
                  if (edge_conf){
                      var edge_model = edge_conf.model;
                      if ( !(edge_model.target in parent_edges) ){
                          parent_edges[ edge_model.target ] = [];
                      }
                      if ( parent_edges[ edge_model.target ].indexOf(edge_model.source) === -1 ){
                          parent_edges[ edge_model.target ].push( edge_model.source );
                      }
                  }
              }
          })
          var ys = {};
          var y_coors = {};

          raw_list.forEach( function(m, _index){
              var new_x = 0;
              var new_y = 0;
              const raw_x = m.x;
              const raw_y = m.y;
              var new_x = start_x + ( m.round - 1) * ( box_width + x_dis );
              var parent_ids = parent_edges[m.id];
              if ( !parent_ids ){
                  // console.log(self.max_win_sequences);
                  if (m.sequence_type === 2){
                    if (m.sequence<=self.max_win_sequences){
                      var new_y1 = start_y + ( self.max_win_sequences+m.sequence ) * ( box_height+y_dis );
                    }else{
                      var new_y1 = start_y + m.sequence * ( box_height+y_dis );
                    }
                    // console.log(new_y, m.id,  self.max_win_sequences, m.sequence);
                  }else{
                    var new_y1 = start_y + ( m.sequence - 1) * ( box_height+y_dis );
                    // console.log(new_y);
                  }

              }else{
                  var sum_parent_y = 0;
                  for (var i in parent_ids){
                      sum_parent_y += ys[ parent_ids[i] ] || 0;
                  }
                  var new_y1 = parseInt( sum_parent_y / parent_ids.length );
              }
              if ( !(m.sequence_type in y_coors ) ){
                  y_coors[m.sequence_type] = {}
              }
              if ( !(m.round in y_coors[m.sequence_type])){
                  y_coors[m.sequence_type][m.round] = 0;
              }
              var new_y2 = y_coors[m.sequence_type][m.round] + 1 * ( box_height + y_dis  );
              new_y = new_y2 > new_y1 ? new_y2: new_y1;
              // console.log( m.sequence_type, m.round, y_coors, parent_ids, new_y1, new_y2, new_y );
              y_coors[m.sequence_type][m.round] = new_y>y_coors[m.sequence_type][m.round]? new_y:y_coors[m.sequence_type][m.round];
              ys[m.id] = new_y;
              if (new_x !== raw_x || new_y !== raw_y){
                  // 更新节点的坐标
                  m.x = new_x;
                  m.y = new_y;
                  self.graph.updateItem( m.id, m );
              }
          })
      },
      // end
      saveToDb(){
          let self = this;
          var nodes = [];
          var edges = [];
          self.graph.getNodes().forEach(function(node,index){
              if ( ! node.desyroyed ){
                  var node_conf = node._cfg;
                  // console.log(edge_conf);
                  if (node_conf ){
                      var node_model = node_conf.model;
                      nodes.push(node_model);
                  }
              }
          })
          self.graph.getEdges().forEach(function(edge,index){
              if ( ! edge.desyroyed ){
                  var _conf = edge._cfg;
                  // console.log(edge_conf);
                  if (_conf ){
                      var _model = _conf.model;
                      if (_model.source !== _model.target){
                          var line = {
                            "source": _model.source,
                            "target": _model.target,
                          }
                          if ( edges.indexOf( line ) === -1 ){
                              edges.push( line );
                          }
                      }
                  }
              }
          })
          // console.log("nodes: ", nodes);
          // console.log("edges: ", edges);
          // 上传到服务器上
          const params = {
            game_id: self.game_id,
            tournament_id: self.tournament_id,
            stage_id: self.stage_id,
            group_id: self.group_id,
            nodes: nodes,
            edges: edges,
          }
          SaveStates( params ).then(response => {
              if (response.data.code === 0) {
                  // 重新生成对阵图
                  self.$emit( "listenRefresh",  JSON.stringify( {
                  }) );
              }else {
                  self.$Message.error(response.data.msg);
              }
          })
      },
      // end
  },
  //
  computed: {
      is_promotion: {
          get: function(){
              let self = this;
              if (self.edit_match.is_promotion === 1){
                return true;
              }else{
                return false
              }
          },
          set: function(newValue){
              let self = this;
              self.edit_match.is_promotion = newValue? 1: 0;
              self.edit_match.promot_name = '';
          },
    }
  }
}
</script>
